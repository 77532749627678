<template>
    <div>
        <!-- DIALOGO DE LOADING -->
        <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
            <ProgressBar mode="indeterminate" style="height: 0.5em" />
            <span>{{ loadingMensaje }}</span>
        </Dialog>

        <!-- DIALOGO PARA SUBIR ACTA -->
        <Dialog :visible.sync="dialogActa" :closable="false" :containerStyle="{ width: '600px' }" :modal="true">
            <div class="p-d-flex p-fluid grid formgrid">
                <div class="text-left field col-4">
                    <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-button-outlined"
                        @click="cerraDialogActa()" />
                </div>
                <div class="text-center field col-4">
                    <h3>CARGAR ACTA CONSTITUTIVA</h3>
                </div>
            </div>
            <hr size=7 color="#E64747">
            <FileUpload name="demo[]" :customUpload="true" @uploader="uploadaActa" accept=".pdf" :maxFileSize="5242880">
                <template #empty>
                    <p>Arrastre y suelte para cargar</p>
                </template>
            </FileUpload>
        </Dialog>

        <!-- DIALOGO PARA SUBIR OPINION -->
        <Dialog :visible.sync="dialogOpinion" :closable="false" :containerStyle="{ width: '600px' }" :modal="true">
            <div class="p-d-flex p-fluid grid formgrid">
                <div class="text-left field col-4">
                    <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-button-outlined"
                        @click="cerraDialogOpinion()" />
                </div>
                <div class="text-center field col-4">
                    <h3>CARGAR OPINION DE CUMPLIMIENTO</h3>
                </div>
            </div>
            <hr size=7 color="#E64747">
            <FileUpload name="demo[]" :customUpload="true" @uploader="uploadaOpinion
                " accept=".pdf" :maxFileSize="5242880">
                <template #empty>
                    <p>Arrastre y suelte para cargar</p>
                </template>
            </FileUpload>
        </Dialog>

        <!-- DIALOGO PARA SUBIR COMPROBANTE -->
        <Dialog :visible.sync="dialogComprobante" :closable="false" :containerStyle="{ width: '600px' }" :modal="true">
            <div class="p-d-flex p-fluid grid formgrid">
                <div class="text-left field col-4">
                    <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-button-outlined"
                        @click="cerraDialogComprobante()" />
                </div>
                <div class="text-center field col-4">
                    <h3>CARGAR COMPROBANTE DE DOMICILIO</h3>
                </div>
            </div>
            <hr size=7 color="#E64747">
            <FileUpload name="demo[]" :customUpload="true" @uploader="uploadaComprobante" accept=".pdf"
                :maxFileSize="5242880">
                <template #empty>
                    <p>Arrastre y suelte para cargar</p>
                </template>
            </FileUpload>
        </Dialog>

        <div class="flex justify-content-between flex-wrap pb-2">
            <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-button-outlined"
                @click="cierraVentana()" />
            <h2>Proveedor</h2>
            <Button @click="guardarOactualizar()" icon="pi pi-save"
                class="p-button-rounded p-button-success p-button-outlined" />
        </div>

        <div class="p-fluid grid">
            <div class="field col-12">
                <hr size=7 color="#E64747">
            </div>
            <!-- RFC -->
            <div class="field col-3">
                <span class="p-float-label">
                    <span class="p-float-label">
                        <InputText id="rfc" type="text" v-model="proveedor.rfc" maxlength="13" />
                        <label for="rfc">RFC</label>
                    </span>
                </span>
            </div>
            <!-- NOMBRE -->
            <div class="field col-9">
                <span class="p-float-label">
                    <span class="p-float-label">
                        <InputText id="nombre" type="text" v-model="proveedor.nombre" />
                        <label for="nombre">Nombre</label>
                    </span>
                </span>
            </div>
            <!-- NOMBRE COMERCIAL -->
            <div class="field col-12">
                <span class="p-float-label">
                    <span class="p-float-label">
                        <InputText id="nombreComercial" type="text" v-model="proveedor.nombreComercial" />
                        <label for="nombreComercial">Nombre Comercial</label>
                    </span>
                </span>
            </div>
            <!-- CORREO -->
            <div class="field col-6">
                <span class="p-float-label">
                    <span class="p-float-label">
                        <InputText id="Correo" type="text" v-model="proveedor.correo" />
                        <label for="Correo">Correo</label>
                    </span>
                </span>
            </div>
            <!-- TELEFONO -->
            <div class="field col-6">
                <span class="p-float-label">
                    <span class="p-float-label">
                        <InputText id="telefono" type="text" v-model="proveedor.telefono" />
                        <label for="telefono">Télefono</label>
                    </span>
                </span>
            </div>
            <!-- DIRECCION -->
            <div class="field col-6">
                <span class="p-float-label">
                    <InputText id="direccion" type="text" v-model="proveedor.direccion" />
                    <label for="direccion">Dirección</label>
                </span>
            </div>
            <!-- CONTACTO -->
            <div class="field col-6">
                <span class="p-float-label">
                    <InputText id="contacto" type="text" v-model="proveedor.contacto" />
                    <label for="contacto">Contacto</label>
                </span>
            </div>
            <div class="field col-12 text-center mb-3 pb-3">
                <h1 class="mb-0 pb-0" style="background: #FF9100;color: white;font-size: 14px;font-family: arial;">
                    DOCUMENTOS
                </h1>
            </div>
            <div class="md:col-4">
                <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                        <Checkbox :binary="true" v-model="validaActa" />
                    </span>
                    <div class="p-inputgroup">
                        <span class="p-float-label">
                            <InputText v-model="proveedor.actaConstitutiva.nombreArchivo" readonly />
                            <label>Acta Constitutiva</label>
                        </span>
                        <Button icon="mdi mdi-upload" class="p-button-success" @click="cargarActa()" />
                    </div>

                </div>
            </div>
            <div class="md:col-4">
                <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                        <Checkbox :binary="true" v-model="validaOpinion" />
                    </span>
                    <div class="p-inputgroup">
                        <span class="p-float-label">
                            <InputText v-model="proveedor.opinionCumplimiento.nombreArchivo" readonly />
                            <label>Opinion de Cumplimento</label>
                        </span>
                        <Button icon="mdi mdi-upload" class="p-button-success" @click="cargarOpinion()" />
                    </div>
                </div>
            </div>
            <div class="md:col-4">
                <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                        <Checkbox :binary="true" v-model="validaComprobante" />
                    </span>
                    <div class="p-inputgroup">
                        <span class="p-float-label">
                            <InputText v-model="proveedor.comprobanteDomicilio.nombreArchivo" readonly />
                            <label>Comprobante de Domicilio</label>
                        </span>
                        <Button icon="mdi mdi-upload" class="p-button-success" @click="cargarComprobante()" />
                    </div>

                </div>
            </div>
            <span hidden>{{ proveedor }}</span>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import { FilterMatchMode, FilterOperator } from 'primevue/api/';
import Button from 'primevue/button/Button';
import PrimeVue from 'primevue/config';
export default {
    data() {
        return {
            loading: false,
            loadingMensaje: "",

            dialogActa: false,
            dialogOpinion: false,
            dialogComprobante: false,
            documento: {
                nombreArchivo: '',
                tipoArchivo: '',
                base64: ''
            }
        };
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
        proveedor() {
            return this.$store.state.proveedorStore;
        },
        validaActa() {
            if (this.proveedor.actaConstitutiva.nombreArchivo == '' || this.proveedor.actaConstitutiva.nombreArchivo == null) {
                return false
            } else {
                return true
            }
        },
        validaOpinion() {
            if (this.proveedor.opinionCumplimiento.nombreArchivo == '' || this.proveedor.opinionCumplimiento.nombreArchivo == null) {
                return false
            } else {
                return true
            }
        },
        validaComprobante() {
            if (this.proveedor.comprobanteDomicilio.nombreArchivo == '' || this.proveedor.comprobanteDomicilio.nombreArchivo == null) {
                return false
            } else {
                return true
            }
        },
    },
    created() {
    },
    methods: {
        guardarOactualizar() {
            if (this.$store.state.proveedorStore.idProveedor == 0) {
                this.postProveedor();
            }
            else {
                this.updateProveedor();
            }
        },
        async postProveedor() {
            this.loading = true;
            this.loadingMensaje = "Guardando Proveedor, espere...";
            this.proveedor.usuarioCreador = this.token.nombre;
            console.log(this.proveedor)
            try {
                let response = await axios.post("ProveedoresDocumentos/PostProveedor/" + this.token.empresaBase, this.proveedor);
                console.log(response.data);
                this.proveedor.idProveedor = response.data;
                this.$store.state.listaProveedorStore.push(this.proveedor);
                this.loading = false;
                this.$toast.add({ severity: "success", summary: "Guardado", detail: "Proveedor guardado con exito.", life: 3000 });
                this.cierraVentana();
            }
            catch (error) {
                console.log(error);
                this.loading = false;
                this.$toast.add({ severity: "error", summary: "Error", detail: "Error al guardar.", life: 3000 });
            }
        },
        async updateProveedor() {
            this.loading = true;
            this.loadingMensaje = "Actualizando Proveedor, espere...";
            this.proveedor.usuarioModificador = this.token.nombre;
            try {
                let response = await axios.put("ProveedoresDocumentos/PutProveedor/" + this.token.empresaBase, this.proveedor);
                console.log(response.data);
                this.loading = false;
                //BUSCAMOS EL INDICE
                let indice = this.$store.state.listaProveedorStore.findIndex(x => x.idProveedor === this.proveedor.idProveedor);
                Object.assign(this.$store.state.listaProveedorStore[indice], this.proveedor);
                this.$toast.add({ severity: "success", summary: "Actualizado", detail: "Proveedor actualizado con exito.", life: 3000 });
                this.cierraVentana();
            }
            catch (error) {
                console.log(error);
                this.loading = false;
                this.$toast.add({ severity: "error", summary: "Error", detail: "Error al actualizar.", life: 3000 });
            }
        },
        cierraVentana() {
            this.$emit("closeProveedor");
            this.$store.state.proveedorStore.idProveedor = -1
        },
        cerraDialogActa() {
            this.dialogActa = false
        },
        cerraDialogOpinion() {
            this.dialogOpinion = false
        },
        cerraDialogComprobante() {
            this.dialogComprobante = false
        },
        cargarActa() {
            this.dialogActa = true
        },
        cargarOpinion() {
            this.dialogOpinion = true
        },
        cargarComprobante() {
            this.dialogComprobante = true
        },

        convertBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = () => reject(reader.error);
            });
        },
        async uploadaActa(event) {

            const file = event.files[0];

            try {
                const result = await this.convertBase64(file);
                this.$store.state.proveedorStore.actaConstitutiva.base64 = result;
                this.$store.state.proveedorStore.actaConstitutiva.nombreArchivo = file.name;
                this.$store.state.proveedorStore.actaConstitutiva.tipoArchivo = 'Acta Constitutiva';
                console.log(this.$store.state.proveedorStore.actaConstitutiva)
                this.dialogActa = false

            } catch (error) {
                console.log('error')
                console.error(error);
                return;
            }
        },
        async uploadaOpinion(event) {
            console.log(event)

            const file = event.files[0];

            try {
                const result = await this.convertBase64(file);
                this.$store.state.proveedorStore.opinionCumplimiento.base64 = result;
                this.$store.state.proveedorStore.opinionCumplimiento.nombreArchivo = file.name;
                this.$store.state.proveedorStore.opinionCumplimiento.tipoArchivo = 'Opinion de Cumplimiento';
                this.dialogOpinion = false
            } catch (error) {
                console.log('error')
                console.error(error);
                return;
            }
        },
        async uploadaComprobante(event) {
            console.log(event)

            const file = event.files[0];

            try {
                const result = await this.convertBase64(file);
                this.$store.state.proveedorStore.comprobanteDomicilio.base64 = result;
                this.$store.state.proveedorStore.comprobanteDomicilio.nombreArchivo = file.name;
                this.$store.state.proveedorStore.comprobanteDomicilio.tipoArchivo = 'Comprobante de Domicilio';
                this.dialogComprobante = false
            } catch (error) {
                console.log('error')
                console.error(error);
                return;
            }
        },
    },
}
</script>