<template>
    <div>
        <!-- DIALOG DE CONTRATO -->
        <Dialog :visible.sync="dialogContrato" :closeOnEscape="true" :closable="false" :containerStyle="{ width: '1100px' }"
            position="top" :modal="true">
            <contrato @closeContrato="CloseDialogContrato()"></contrato>
        </Dialog>
        <!-- DIALOG DE COMPROBANTES -->
        <Dialog :visible.sync="dialogComprobantes" :closeOnEscape="true" :closable="false"
            :containerStyle="{ width: '90vw' }" position="top" :modal="true">
            <asigna-comprobantes @closeComprobantes="CloseDialogComprobantes()"></asigna-comprobantes>
        </Dialog>

        <Card class="shadow-none">
            <template #content>
                <div class="p-fluid grid">
                    <!-- TITULO -->
                    <div class="col-8 text-left pa-0 ma-0 pl-3">
                        <h2>Contratos</h2>
                    </div>
                    <!-- BOTONES -->
                    <div class="col-4 text-right">
                        <Button icon="pi pi-search" class="p-button-success pl-4 pr-4" @click="GetContratos()" />
                        <Button icon="pi pi-plus" class="p-button-success pl-4 pr-4 mx-1" @click="OpenDialogContrato()" />
                    </div>
                </div>
                <div class="field col-12 mt-2 mb-0">
                    <hr size=7 color="#E64747">
                </div>
                <DataTable filterDisplay="menu" removableSort :exportFilename="nombreExcel" ref="contratos" showGridlines
                    :filters.sync="filtros" class="mt-1 p-datatable-sm" :value="itemsContratos" :scrollable="true"
                    scrollHeight="550px" scrollDirection="both" :loading="loading"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    :rowsPerPageOptions="[5, 10, 15, 25, 50]"
                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} Registros." :paginator="true"
                    :rows="15">
                    <template #header>
                        <div class="p-fluid grid">
                            <div class="col-4 text-left ">
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search" />
                                    <InputText v-model="filtros['global'].value" placeholder="Buscar" />
                                </span>
                            </div>
                            <div class="col-8 text-right ">
                                <Button type="button" icon="pi pi-filter-slash" style="width: 100px;" label="Limpiar"
                                    class="p-button-outlined mr-2" @click="limpiarFiltros()" />
                                <Button icon="pi pi-external-link" style="width: 200px;" label="Exportar Excel"
                                    @click="exportarContratos($event)" />
                            </div>
                        </div>
                    </template>
                    <Column header="Acciones" :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }" frozen
                        :styles="{ 'flex-grow': '1', 'flex-basis': '50px', 'min-width': '5rem', }">
                        <template #body="slotProps">
                            <Button @click="OpenDialogAsignaComprobantes(slotProps.data)" icon="mdi mdi-file-document-plus"
                                class="p-button-rounded p-button-success" v-tooltip.bottom="'Asignar comprobantes'" />
                            <Button @click="OpenDialogComprobantesAsignados(slotProps.data)" icon="mdi mdi-file-eye"
                                class="p-button-rounded p-button-info" v-tooltip.bottom="'Ver comprobantes'" />
                        </template>
                    </Column>
                    <Column sortable field="nombre" header="Contrato"
                        :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '50px', 'font-size': '90%' }">
                        <template #filter="{ filterModel }">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="Buscar" />
                        </template>
                    </Column>
                    <Column sortable field="rfc" header="RFC"
                        :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '50px', 'font-size': '90%' }">
                        <template #filter="{ filterModel }">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="Buscar" />
                        </template>
                    </Column>
                    <Column sortable field="nombreProveedor" header="Nombre"
                        :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '50px', 'font-size': '90%' }">
                        <template #filter="{ filterModel }">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="Buscar" />
                        </template>
                    </Column>
                    <Column sortable field="cuenta" header="Cuenta OG"
                        :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '50px', 'font-size': '90%' }">
                        <template #filter="{ filterModel }">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="Buscar" />
                        </template>
                    </Column>

                    <Column sortable field="presupuesto" header="Aprobado"
                        :bodyStyle="{ 'justify-content': 'right', overflow: 'visible' }"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%', 'text-align': 'right' }">
                        <template #body="{ data }">
                            <span>{{ formatCurrency(data.presupuesto) }}</span>
                        </template>
                        <template #filter="{ filterModel }">
                            <InputNumber v-model="filterModel.value" mode="currency" currency="USD" locale="en-US" />
                        </template>
                    </Column>
                    <Column sortable field="comprobantes" header="Ejercido"
                        :bodyStyle="{ 'justify-content': 'right', overflow: 'visible' }"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%', 'text-align': 'right' }">
                        <template #body="{ data }">
                            <span>{{ formatCurrency(data.comprobantes) }}</span>
                        </template>
                        <template #filter="{ filterModel }">
                            <InputNumber v-model="filterModel.value" mode="currency" currency="USD" locale="en-US" />
                        </template>
                    </Column>
                    <Column sortable field="pagado" header="Pagado"
                        :bodyStyle="{ 'justify-content': 'right', overflow: 'visible' }"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%', 'text-align': 'right' }">
                        <template #body="{ data }">
                            <span>{{ formatCurrency(data.pagado) }}</span>
                        </template>
                        <template #filter="{ filterModel }">
                            <InputNumber v-model="filterModel.value" mode="currency" currency="USD" locale="en-US" />
                        </template>
                    </Column>
                    <Column sortable field="disponible" header="Disponible"
                        :bodyStyle="{ 'justify-content': 'right', overflow: 'visible' }"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%', 'text-align': 'right' }">
                        <template #body="{ data }">
                            <span>{{ formatCurrency(data.disponible) }}</span>
                        </template>
                        <template #filter="{ filterModel }">
                            <InputNumber v-model="filterModel.value" mode="currency" currency="USD" locale="en-US" />
                        </template>
                    </Column>
                    <ColumnGroup type="footer">
                        <Row>
                            <Column footer="Total:" :colspan="5" :footerStyle="{ 'text-align': 'right' }" />
                            <Column :footer="sumaPresupuesto" :footerStyle="{ 'text-align': 'right' }"
                                :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%', 'text-align': 'right' }" />
                        </Row>
                    </ColumnGroup>
                </DataTable>
            </template>
        </Card>
    </div>
</template>
<script>
import axios from 'axios'
import moment from 'moment'
import { FilterMatchMode, FilterOperator } from "primevue/api/";
import Contrato from './Contratos'
import AsignaComprobantes from './AsignaComprobantes'

export default {
    components: {
        Contrato,
        AsignaComprobantes,
    },
    data() {
        return {
            fechaI: new Date(),
            fechaF: new Date(),
            loading: false,
            filtros: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                'rfc': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'nombre': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'nombreProveedor': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'cuenta': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'presupuesto': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            },

            dialogDetalles: false,
            dialogContrato: false,
            nombreExcel: '',
            tituloDetalles: '',
            fechaAHeader: '',
            fechaBHeader: '',

            dialogComprobantes: false,
        };
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },

        itemsContratos() {
            return this.$store.state.itemsContratosStore;
        },

        sumaPresupuesto() {
            let total = 0;
            for (let item of this.itemsContratos) {
                total += item.presupuesto;
            }

            return this.formatCurrency(total);
        },
    },
    created() {
        this.GetContratos();
    },
    methods: {
        async GetContratos() {
            this.$store.state.itemsContratosStore = []
            this.loading = true;
            try {
                let response = await axios.get('Contratos/GetContratos/' + this.token.empresaBase);
                console.log(response.data)
                this.$store.state.itemsContratosStore = response.data;
                this.loading = false;
            } catch (error) {
                console.log(error);
                this.loading = false;
            }
        },

        exportarContratos() {
            // this.nombreExcel = 'Contratos.xlsx'
            // // this.$refs.gastosMes.exportCSV();
            // const rows = this.$refs.contratos.processedData; // Obtén los datos de la DataTable
            // console.log(rows)
            // const worksheet = XLSX.utils.json_to_sheet(rows);
            // const workbook = XLSX.utils.book_new();
            // XLSX.utils.book_append_sheet(workbook, worksheet, 'Hoja1');

            // XLSX.writeFile(workbook, this.nombreExcel);
        },

        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },

        OpenDialogContrato() {
            this.dialogContrato = true;
            let contrato = {
                id: 0,
                nombre: '',
                fecha: new Date(),
                proveedor: {
                    proveedor: ''
                },
                presupuesto: 0,
                cuentaOg: {
                    cuenta: '',
                },
            };
            this.$store.state.contratoStore = { ...contrato }
        },

        CloseDialogContrato() {
            this.dialogContrato = false;
        },
        limpiarFiltros() {
            this.filtros = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                'rfc': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'nombre': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'nombreProveedor': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'cuenta': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'presupuesto': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            }
        },

        async OpenDialogAsignaComprobantes(item) {
            console.log(item)
            let rfc = item.rfc
            this.loading = true;
            let cabecera = {
                empresa: item.rfc + ' | ' + item.nombreProveedor,
                contrato: item.nombre,
                idContrato: item.id
            }
            try {
                let response = await axios.get('Contratos/GetComprobantesSinContratoRfc/' + this.token.empresaBase + '/' + rfc);
                console.log(response.data)
                // this.$store.state.itemsContratosStore = response.data;
                this.$store.state.ComprobantesContratosStore = response.data;
                this.$store.state.ComprobantesContratosSelectedStore = []
                this.$store.state.CabeceraStore = { ...cabecera }
                this.loading = false;
                this.dialogComprobantes = true;
            } catch (error) {
                console.log(error)
                this.loading = false;
            }
        },

        async OpenDialogComprobantesAsignados(item) {
            this.loading = true;
            let cabecera = {
                empresa: item.rfc + ' | ' + item.nombreProveedor,
                contrato: item.nombre,
                idContrato: item.id
            }

            try {
                let response = await axios.get('Contratos/GetComprobantesAsignados/' + this.token.empresaBase + '/' + item.id);
                console.log(response.data);
                this.$store.state.ComprobantesContratosStore = response.data;
                this.$store.state.ComprobantesContratosSelectedStore = response.data
                this.$store.state.CabeceraStore = { ...cabecera }
                this.loading = false;
                this.dialogComprobantes = true;
            } catch (error) {

            }
        },

        CloseDialogComprobantes() {
            this.dialogComprobantes = false;
        },

    },
}
</script>