<template>
    <div>
        <!-- DIALOGO DE LOADING -->
        <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
            <ProgressBar mode="indeterminate" style="height: 0.5em" />
            <span>{{ loadingMensaje }}</span>
        </Dialog>

        <!-- NUEVO CATALOGO -->
        <Dialog :visible.sync="dialogNuevoObjeto" :closeOnEscape="false" :closable="false" :style="{ width: '500px' }"
            :maximizable="false" position="top" :modal="true">
            <Catalogo @closeCatalogo="closeDialogCatalogo()"></Catalogo>
        </Dialog>

        <!-- DIALOGO PARA CARGAR EXCEL -->
        <Dialog header="Cargar Excel" :visible.sync="dialogCargarExcel" :closeOnEscape="true" :closable="true"
            :style="{ width: '700px' }" :maximizable="false" position="top" :modal="true">
            <div class="p-fluid grid">
                <div class="field col-12">
                    <hr size=7 color="#E64747">
                </div>
                <div class="col-12 pa-0 ma-0 pl-3">
                    <FileUpload name="demo[]" :customUpload="true" @uploader="onUpload" accept=".xlsx"
                        :maxFileSize="5242880">
                        <template #empty>
                            <p>Drag and drop files to here to upload.</p>
                        </template>
                    </FileUpload>
                </div>
                <div class="col-12 text-right pa-0 ma-0 pl-3">
                    <Button style="width: 150px;" label="Guardar" icon="pi pi-save" class="ml-3" @click="guardarExcel()" />
                </div>
            </div>
            <DataTable removableSort showGridlines class="mt-1 p-datatable-sm" :value="itemsExcel"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                :rowsPerPageOptions="[5, 10, 15, 25, 50]"
                currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} Registros." :paginator="true"
                :rows="10">

                <Column sortable field="numero" header="Número"
                    :bodyStyle="{ 'justify-content': 'left', overflow: 'visible' }"
                    :styles="{ 'flex-grow': '1', 'flex-basis': '400px', 'font-size': '100%' }">
                </Column>

                <Column sortable field="nombre" header="Nombre"
                    :bodyStyle="{ 'justify-content': 'left', overflow: 'visible' }"
                    :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '100%', 'text-align': 'left' }">
                </Column>
                <Column sortable field="tipo" header="Tipo"
                    :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }"
                    :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '100%', 'text-align': 'center' }">
                </Column>
            </DataTable>
        </Dialog>

        <Card class="shadow-none">
            <template #content>
                <div class="p-fluid grid">
                    <!-- TITULO -->
                    <div class="col-6 text-left pa-0 ma-0 pl-3">
                        <h2>Catálogo Objeto de Gasto</h2>
                    </div>
                    <div class="col-6 text-right pa-0 ma-0 pl-3">
                        <Button style="width: 40px;" icon="mdi mdi-reload" class="p-button-sm p-button-help mr-1"
                            @click="getCatalogo()" />
                        <Button style="width: 150px;" label="Nuevo" icon="pi mdi mdi-plus" class="p-button-sm  mr-1"
                            @click="nuevoCatalogo()" />
                        <Button style="width: 250px;" label="Cargar Excel" icon="mdi mdi-microsoft-excel "
                            class="p-button-sm p-button-success" @click="abrirCargarExcel()" />
                    </div>
                    <div class="field col-12 mt-2 mb-0">
                        <hr size=7 color="#E64747">
                    </div>
                </div>
                <DataTable removableSort :exportFilename="nombreExcel" ref="catalogo" showGridlines :filters.sync="filtros"
                    class="mt-1 p-datatable-sm" :value="itemsCatalogo" :scrollable="true" scrollHeight="600px"
                    scrollDirection="both" :loading="loadingCatalogo"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    :rowsPerPageOptions="[5, 10, 15, 25, 50]"
                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} Registros." :paginator="true"
                    :rows="15">
                    <template #header>
                        <div class="flex justify-content-between">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filtros['global'].value" placeholder="Buscar" />
                            </span>
                            <Button icon="pi pi-external-link" label="Exportar Excel" @click="exportarCatalogo($event)" />
                        </div>
                    </template>

                    <!-- <Column field="id" header="ID" :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '50px', 'font-size': '100%' }">
                    </Column> -->

                    <Column sortable field="numero" header="Número"
                        :bodyStyle="{ 'justify-content': 'left', overflow: 'visible' }"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '400px', 'font-size': '100%' }">
                    </Column>

                    <Column sortable field="nombre" header="Nombre"
                        :bodyStyle="{ 'justify-content': 'left', overflow: 'visible' }"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '100%', 'text-align': 'right' }">
                    </Column>
                    <!-- <Column field="tipo" header="Tipo" :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '100%', 'text-align': 'right' }">
                    </Column> -->
                </DataTable>
            </template>
        </Card>
    </div>
</template>
<script>
import axios from 'axios'
import moment from 'moment'
import Catalogo from './Catalogo.vue'
import readXlsFile from 'read-excel-file'

import { FilterMatchMode, FilterOperator } from "primevue/api/";

export default {
    components: {
        Catalogo
    },
    data() {
        return {
            loading: false,
            loadingMensaje: "",

            loadingCatalogo: false,
            itemsExcel: [],
            filtros: {
                global: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            },
            dialogDetalles: false,
            nombreExcel: '',
            tituloDetalles: '',
            dialogNuevoObjeto: false,
            dialogCargarExcel: false,
        };
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
        itemsCatalogo() {
            return this.$store.state.listaCatalogoStore;
        }

    },
    created() {
        this.getCatalogo();
    },
    methods: {
        async getCatalogo() {
            this.loadingCatalogo = true;
            this.$store.state.listaCatalogoStore = []
            try {
                let response = await axios.get('Contratos/GetCuentaOg/' + this.token.empresaBase);
                console.log(response.data)
                this.$store.state.listaCatalogoStore = response.data
                this.loadingCatalogo = false;
            } catch (error) {
                console.log(error);
                this.loadingCatalogo = false;
            }
        },
        nuevoCatalogo() {
            let nuevoCatalogo = {
                id: 0,
                numero: '',
                nombre: '',
            }
            if (this.$store.state.catalogoStore.id != 0) {
                this.$store.state.catalogoStore = { ...nuevoCatalogo }
            }
            this.dialogNuevoObjeto = true
        },
        abrirCargarExcel() {
            this.dialogCargarExcel = true
        },
        closeDialogCatalogo() {
            this.dialogNuevoObjeto = false;
        },
        exportarCatalogo() {
            this.nombreExcel = 'CatalogoObjetoGasto'
            this.$refs.catalogo.exportCSV();
        },
        onUpload(event) {
            this.itemsExcel = []
            const file = event.files[0];
            console.log(file)
            readXlsFile(file).then((rows) => {
                let num = rows.length;
                for (var cont = 1; cont < num; cont++) {
                    try {
                        let objeto = {}
                        objeto.numero = rows[cont]['0']
                        objeto.nombre = rows[cont]['1']
                        objeto.tipo = rows[cont]['2']

                        this.itemsExcel.push(objeto)
                    } catch (error) {
                        console.log(error)
                    }
                }
            });
        },
        async guardarExcel() {
            this.loading = true;
            this.loadingMensaje = "Guardando espere...";
            try {
                let response = await axios.post("Contratos/PostListaObjetoGasto/" + this.token.empresaBase, this.itemsExcel);
                console.log(response.data);
                this.loading = false;
                this.$toast.add({ severity: "success", summary: "Guardado", detail: "guardado con exito.", life: 3000 });
                this.getCatalogo()
            }
            catch (error) {
                console.log(error);
                this.loading = false;
                this.$toast.add({ severity: "error", summary: "Error", detail: "Error al guardar.", life: 3000 });
            }
        },
        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },
    },
}
</script>