<template>
    <div>
        <Card class="shadow-none">
            <template #content>
                <div class="p-fluid grid">
                    <!-- TITULO -->
                    <div class="col-8 text-left pa-0 ma-0 pl-3">
                        <H2>Reporte por Catalogo</H2>
                    </div>
                    <!-- FECHAS -->
                    <div class="col-4 text-right">
                        <div class="text-left p-d-flex justify-center p-fluid grid formgrid">
                            <div class="col-12 md:col-12 text-left">
                                <div class="p-inputgroup">
                                    <span class="p-float-label">
                                        <Calendar id="monthpicker" v-model="fechaIncial" dateFormat="dd/MM/yy"
                                            :yearNavigator="true" yearRange="2020:2030" />
                                        <label>Fecha Inicial</label>
                                    </span>
                                    <span class="p-float-label">
                                        <Calendar id="monthpicker" v-model="fechaFinal" dateFormat="dd/MM/yy"
                                            :yearNavigator="true" yearRange="2020:2030" />
                                        <label>Fecha Final</label>
                                    </span>
                                    <Button icon="pi pi-search" class="p-button-success pl-4 pr-4" @click="getGastos()" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="field col-12 mt-2 mb-0">
                        <hr size=7 color="#E64747">
                    </div>
                    <!-- <div class="field col-12 mt-2 mb-0"> -->


                </div>
                <DataTable filterDisplay="menu" :filters.sync="filtros" removableSort :exportFilename="nombreExcel"
                    ref="catalogo" showGridlines class="mt-1 p-datatable-sm" :value="itemsCatalogo" scrollHeight="600px"
                    :loading="loadingCatalogo"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    :rowsPerPageOptions="[5, 10, 15, 25, 50]"
                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} Registros." :paginator="true"
                    :rows="15">
                    <template #header>
                        <div class="flex justify-content-between">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filtros['global'].value" placeholder="Buscar" />
                            </span>
                            <Button icon="pi pi-external-link" label="Exportar Excel" @click="exportarCatalogo($event)" />
                        </div>
                    </template>
                    <Column sortable field="numero" header="Número"
                        :bodyStyle="{ 'justify-content': 'left', overflow: 'visible' }">
                        <template #filter="{ filterModel }">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="Buscar" />
                        </template>
                    </Column>

                    <Column sortable field="nombre" header="Nombre"
                        :bodyStyle="{ 'justify-content': 'left', overflow: 'visible' }">
                        <template #filter="{ filterModel }">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="Buscar" />
                        </template>
                    </Column>
                    <Column sortable field="total" header="Total"
                        :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '100%', 'text-align': 'right' }">
                        <template #body="{ data }">
                            <span>{{ formatCurrency(data.total) }}</span>
                        </template>
                        <template #filter="{ filterModel }">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="Buscar" />
                        </template>
                    </Column>
                </DataTable>
                <!-- </div> -->
            </template>
        </Card>
    </div>
</template>
<script>
import axios from 'axios'
import moment from 'moment'
import { FilterMatchMode, FilterOperator } from "primevue/api/";

export default {
    components: {
    },
    data() {
        return {
            fechaIncial: new Date(),
            fechaFinal: new Date(),
            itemsCatalogo: [],
            loadingCatalogo: false,
            nombreExcel: '',
            filtros: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                'numero': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'nombre': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'total': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            }
        };
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
    },
    created() {
    },
    methods: {
        async getGastos() {
            this.loadingCatalogo = true;
            this.itemsCatalogo = []
            try {
                let fI = moment(this.fechaIncial).format('YYYY-MM-DD')
                let fF = moment(this.fechaFinal).format('YYYY-MM-DD')
                let response = await axios.get('ReporteComprobantesGastos/GetCuentaGastoReporte/' + this.token.empresaBase + '/' + fI + '/' + fF);
                console.log(response.data)
                this.itemsCatalogo = response.data.filter(x => x.total > 0);

                this.loadingCatalogo = false;
            } catch (error) {
                console.log(error);
                this.loadingCatalogo = false;
            }
        },
        exportarCatalogo() {
            // // this.$refs.catalogo.exportCSV();
            // const rows = this.$refs.catalogo.processedData; // Obtén los datos de la DataTable
            // console.log(rows)
            // const worksheet = XLSX.utils.json_to_sheet(rows);
            // const workbook = XLSX.utils.book_new();
            // XLSX.utils.book_append_sheet(workbook, worksheet, 'Hoja1');

            // XLSX.writeFile(workbook, 'ReporteCatalogo.xlsx');
        },
        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },

    },
}
</script>