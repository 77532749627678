<template>
    <div>
        <Dialog :baseZIndex="2" :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }"
            :modal="true">
            <ProgressBar mode="indeterminate" style="height: 0.5em" />
            <span>{{ loadingMensaje }}</span>
        </Dialog>

        <Dialog :visible.sync="dialogNotas" :closable="false" :containerStyle="{ width: '600px' }" :modal="true">
            <div class="card">
                <div class="p-d-flex p-fluid grid formgrid">
                    <div class="text-left field col-3">
                        <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-button-outlined"
                            @click="dialogNotas = false" />
                    </div>
                    <div class="text-center field col-6">
                        <h2>{{ textoNotas }}</h2>
                    </div>

                </div>
                <hr size=7 color="#ED6421">
                <div>
                    <Editor readonly :value="detalleNotas" class="editor1" style="font-size: 15px;">
                        <template hidden #toolbar>
                            <span hidden class="ed ql-formats">
                            </span>
                        </template>        
                    </Editor>
                </div>
            </div>
        </Dialog>

        <div class="card">
            <div class="flex justify-content-center flex-wrap card-container">
                <div class="flex align-items-center justify-content-center w-14rem h-4rem m-2">
                    <span class="p-float-label">
                        <Dropdown style="text-align: left; width:200px" v-model="selectedMes" :options="itemsMes"
                            optionLabel="mes" />
                        <label>Mes</label>
                    </span>
                </div>
                <div class="flex align-items-center justify-content-center w-4rem h-4rem m-2">
                    <span class="p-float-label">
                        <Dropdown style="text-align: left; width:110px" v-model="selectedAnio" :options="itemsAnios" />
                        <label>Año</label>
                    </span>
                </div>
                <div class="flex align-items-center justify-content-center w-6rem h-4rem m-2">
                    <div>
                        <Button icon="mdi mdi-text-box-search-outline" class="p-button-text p-button-rounded"
                            @click="Consultar()" v-tooltip.bottom="'Consultar'" style="color:#FBC31F" />
                    </div>
                    <div>
                        <Button icon="mdi mdi-file-excel-box" class="p-button-text p-button-rounded" @click="ExportExcel()"
                            v-tooltip.bottom="'Exportar a Excel'" style="color:#107C41" />
                    </div>
                </div>
            </div>
        </div>
        <DataTable :value="listArrendamiento" :scrollable="true" scrollHeight="560px" scrollDirection="both">
            <template #header>
                <div class="text-center p-d-flex justify-center p-fluid grid formgrid">
                    <div class="col-12 text-center pa-0 ma-0 pl-3">
                        <h1>Arrendamiento ({{ sumaArrendamiento }})</h1>
                        <Button icon="mdi mdi-information" class="p-button-text p-button-rounded" @click="OpenNotas(1)"
                            v-tooltip.bottom="'Información'" style="color:#24ACF2" />
                    </div>
                </div>
            </template>
            <Column field="mes" header="MES" :styles="{ 'flex-grow': '1', 'flex-basis': '200px' }"></Column>
            <Column field="importe" header="IMPORTE" :styles="{ 'flex-grow': '1', 'flex-basis': '150px' }">
                <template #body="{ data }">
                    <span>{{ formatCurrency(data.importe) }}</span>
                </template>
            </Column>
        </DataTable>

        <DataTable :value="listHonorarios" :scrollable="true" scrollHeight="560px" scrollDirection="both">
            <template #header>
                <div class="text-center p-d-flex justify-center p-fluid grid formgrid">
                    <div class="col-12 text-center pa-0 ma-0 pl-3">
                        <h1>Honorarios ({{ sumaHonorarios }})</h1>
                        <Button icon="mdi mdi-information" class="p-button-text p-button-rounded" @click="OpenNotas(2)"
                            v-tooltip.bottom="'Información'" style="color:#24ACF2" />
                    </div>
                </div>
            </template>
            <Column field="mes" header="MES" :styles="{ 'flex-grow': '1', 'flex-basis': '200px' }"></Column>
            <Column field="importe" header="IMPORTE" :styles="{ 'flex-grow': '1', 'flex-basis': '150px' }">
                <template #body="{ data }">
                    <span>{{ formatCurrency(data.importe) }}</span>
                </template>
            </Column>
        </DataTable>
        <br>
    </div>
</template>
<script>
import axios from 'axios'
import moment from 'moment'
import XLSX from 'xlsx'

export default {
    components: {},
    data() {
        return {
            listArrendamiento: [],
            listHonorarios: [],

            itemsAnios: ['2023', '2022', '2021', '2020', '2019', '2018'],
            itemsMes: [
                { mes: 'ENERO', valor: 1 },
                { mes: 'FEBRERO', valor: 2 },
                { mes: 'MARZO', valor: 3 },
                { mes: 'ABRIL', valor: 4 },
                { mes: 'MAYO', valor: 5 },
                { mes: 'JUNIO', valor: 6 },
                { mes: 'JULIO', valor: 7 },
                { mes: 'AGOSTO', valor: 8 },
                { mes: 'SEPTIEMBRE', valor: 9 },
                { mes: 'OCTUBRE', valor: 10 },
                { mes: 'NOVIEMBRE', valor: 11 },
                { mes: 'DICIEMBRE', valor: 12 },
            ],

            selectedAnio: null,
            selectedMes: null,

            //LOADING
            loading: false,
            loadingMensaje: '',

            //NOTAS
            dialogNotas: false,
            textoNotas: '',
            detalleNotas: '',
        };
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },

        sumaArrendamiento() {
            let suma = this.listArrendamiento.reduce((acumulador, objeto) => acumulador + objeto.importe, 0);
            let resultado = this.formatCurrency(suma);
            return this.formatCurrency(suma);
        },

        sumaHonorarios() {
            let suma = this.listHonorarios.reduce((acumulador, objeto) => acumulador + objeto.importe, 0);
            let resultado = this.formatCurrency(suma);
            return this.formatCurrency(suma);
        },
    },
    created() {
    },
    methods: {
        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },

        async Consultar() {
            try {
                this.loading = true;
                this.loadingMensaje = 'Consultando, espere...'

                let fechaI = this.selectedAnio + '-01-01';
                let fechaF = this.selectedAnio + '-' + this.selectedMes.valor + '-01';
                let response = await axios.get('ReporteComprobantesGastos/GetIsrAyH/' + this.token.empresaBase + '/' + fechaI + '/' + fechaF);
                let x = response.data;
                this.listArrendamiento = x[0];
                this.listHonorarios = x[1];
                this.loading = false
            } catch (error) {
                console.log(error)
                this.loading = false
            }
        },

        OpenNotas(item) {
            this.dialogNotas = true;
            if (item == 1) {
                this.textoNotas = 'Arrendamiento';
                this.detalleNotas = 'Se incluyen todos aquellos comprobantes que dentro de su descripción, contengan la clave 80131500 | Alquiler y arrendamiento de propiedades o edificacione, así como sus subclaves';
            }
            if (item == 2) {
                this.textoNotas = 'Honorarios';
                this.detalleNotas = 'Se incluyen todos los comprobantes diferentes a la clave de arrendamiento';
            }
        },

        ExportExcel() {
            const workbook = XLSX.utils.book_new();

            const columns = ['mes', 'importe'];
            const dataArrendamiento = this.listArrendamiento.map(x => {
                const fila = {};
                columns.forEach(column => {
                    fila[column] = x[column];
                });
                return fila;
            });

            const dataHonorarios = this.listHonorarios.map(x => {
                const fila = {};
                columns.forEach(column => {
                    fila[column] = x[column];
                });
                return fila;
            });

            const sheetArrendamiento = XLSX.utils.json_to_sheet(dataArrendamiento);
            XLSX.utils.book_append_sheet(workbook, sheetArrendamiento, 'ARRENDAMIENTO');

            const sheetHonorarios = XLSX.utils.json_to_sheet(dataHonorarios);
            XLSX.utils.book_append_sheet(workbook, sheetHonorarios, 'HONORARIOS');

            XLSX.writeFile(workbook, 'Retenciones_Isr_ENERO_' + this.selectedMes.mes + '_' + this.selectedAnio + '.xlsx');
        },

    },
}
</script>