<template>
    <div>
        <!-- DIALOGO PARA ERRORES -->
        <div class="p-d-flex">
            <Toast />
        </div>
        <div class="card">
            <TabView>
                <TabPanel header="Lista de Contratos">
                    <lista-contratos></lista-contratos>
                </TabPanel>
                <TabPanel header="Conciliar Contratos">
                    <conciliar-contratos></conciliar-contratos>
                </TabPanel>
                <TabPanel header="Reporte por Catalogo">
                    <ObjetoGastosReporte></ObjetoGastosReporte>
                </TabPanel>
            </TabView>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import ListaContratos from './ListaContratos'
import ConciliarContratos from './ConciliarContratos'
import ObjetoGastosReporte from './ObjetoGastosReporte'

export default {
    components: {
        ListaContratos,
        ConciliarContratos, ObjetoGastosReporte
    },
    data() {
        return {
        };
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
    },
    created() {
    },
    methods: {


    },
}
</script>