<template>
    <div>
        <!-- DIALOGO DE LOADING -->
        <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
            <ProgressBar mode="indeterminate" style="height: 0.5em" />
            <span>{{ loadingMensaje }}</span>
        </Dialog>

        <!-- DIALOG DE CONTRATO -->
        <Dialog :visible.sync="dialogContrato" :closeOnEscape="true" :closable="false" :containerStyle="{ width: '1100px' }"
            position="top" :modal="true">
            <contratos @closeContrato="CloseDialogContrato()"></contratos>
        </Dialog>

        <Card class="shadow-none">
            <template #content>
                <div class="p-fluid grid">
                    <!-- TITULO -->
                    <div class="col-8 text-left pa-0 ma-0 pl-3">
                        <h2>Conciliar Contratos</h2>
                    </div>
                    <!-- BOTONES DE ACCION -->
                    <div class="col-4 text-right">
                        <Button icon="pi pi-search" class="p-button-success pl-4 pr-4" @click="GetConciliaContratos()" />
                    </div>
                </div>
                <div class="field col-12 mt-2 mb-0">
                    <hr size=7 color="#E64747">
                </div>

                <!-- LISTA DE PROVEEDORES -->
                <DataTable style="width:100%; " class=" p-datatable-sm" :value="itemsProveedores" responsiveLayout="scroll"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    :rowsPerPageOptions="[5, 10, 15, 25, 50]"
                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} Registros." :paginator="true"
                    :rows="10">
                    <template #header>
                        <h1>Proveedores no registrados con contrato</h1>
                    </template>
                    <Column header="Acciones" :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }" frozen
                        :styles="{ 'flex-grow': '1', 'flex-basis': '50px', 'min-width': '5rem', }">
                        <template #body="slotProps">
                            <Button @click="PostProveedor(slotProps.data)" icon="pi pi-file-pdf"
                                class="p-button-rounded p-button-success" v-tooltip.bottom="'Alta de Proveedor'" />
                        </template>
                    </Column>
                    <Column field="rfc" header="RFC" :headerStyle="{ 'min-width': '100px', 'text-align': 'center' }"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '50px', 'text-align': 'left' }">
                    </Column>
                    <Column field="nombre" header="Nombre" :headerStyle="{ 'min-width': '100px', 'text-align': 'center' }"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '50px', 'text-align': 'left' }">
                    </Column>
                </DataTable>

                <!-- LISTA DE CONTRATOS SIN REGISTRAR -->
                <DataTable style="width:100%; " ref="contratosNoRegistrados" class=" p-datatable-sm" :value="itemsContratos"
                    responsiveLayout="scroll" :filters.sync="filtros"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    :rowsPerPageOptions="[5, 10, 15, 25, 50]"
                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} Registros." :paginator="true"
                    :rows="10">
                    <template #header>
                        <div class="flex justify-content-between">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filtros['global'].value" placeholder="Buscar" />
                            </span>
                            <h1>Contratos no registrados</h1>
                            <Button icon="pi pi-external-link" label="Exportar Excel" @click="exportarArchivo($event)" />
                        </div>
                    </template>
                    <Column header="Acciones" :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }" frozen
                        :styles="{ 'flex-grow': '1', 'flex-basis': '50px', 'min-width': '5rem', }">
                        <template #body="slotProps">
                            <Button @click="OpenDialogContrato(slotProps.data)" icon="pi pi-file-pdf"
                                class="p-button-rounded p-button-success" v-tooltip.bottom="'Alta de contrato'" />
                        </template>
                    </Column>
                    <Column sortable field="contrato" header="Contrato"
                        :bodyStyle="{ 'justify-content': 'left', overflow: 'visible' }"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '50px', 'font-size': '90%' }">
                    </Column>
                    <Column sortable field="rfc" header="RFC"
                        :bodyStyle="{ 'justify-content': 'left', overflow: 'visible' }"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '50px', 'font-size': '90%' }">
                    </Column>
                    <Column sortable field="nombre" header="Nombre"
                        :bodyStyle="{ 'justify-content': 'left', overflow: 'visible' }"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '50px', 'font-size': '90%' }">
                    </Column>
                </DataTable>
            </template>
        </Card>
    </div>
</template>
<script>
import axios from 'axios'
import moment from 'moment'
import { FilterMatchMode, FilterOperator } from "primevue/api/";
import Contratos from './Contratos'

export default {
    components: {
        Contratos,
    },
    data() {
        return {
            loading: false,
            // itemsContratos: [],
            filtros: {
                global: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            },
            nombreExcel: '',
            itemsProveedores: [],

            loading: false,
            loadingMensaje: '',

            dialogContrato: false,
        };
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
        listProveedores() {
            return this.$store.state.listaProveedorStore;
        },
        itemsContratos() {
            return this.$store.state.itemsContratosConciliaStore;
        },

    },
    created() {
        this.GetProveedores();
    },
    methods: {
        async GetProveedores() {
            this.loadingProveedor = true
            try {
                let response = await axios.get('ProveedoresDocumentos/GetProveedoresSimplificado/' + this.token.empresaBase);
                console.log(response.data)
                this.$store.state.listaProveedorStore = response.data
                this.loadingProveedor = false
            } catch (error) {
                console.log(error);
                this.loadingProveedor = false
            }
        },

        async GetConciliaContratos() {
            this.$store.state.itemsContratosConciliaStore = []
            this.itemsProveedores = []
            this.loading = true;
            this.loadingMensaje = 'Consultando, espere...'
            try {
                let response = await axios.get('Contratos/GetConciliaContratos/' + this.token.empresaBase);
                let respuesta = response.data;
                console.log(response.data)
                // this.itemsContratos = response.data;
                this.itemsProveedores = respuesta.proveedores
                this.$store.state.itemsContratosConciliaStore = respuesta.contratosNuevos
                this.loading = false;
            } catch (error) {
                console.log(error);
                this.loading = false;
            }
        },

        exportarArchivo() {
            // this.nombreExcel = 'contratosNoRegistrados.xlsx'
            // // this.$refs.contratosNoRegistrados.exportCSV();
            // const rows = this.$refs.contratosNoRegistrados.processedData; // Obtén los datos de la DataTable
            // console.log(rows)
            // const worksheet = XLSX.utils.json_to_sheet(rows);
            // const workbook = XLSX.utils.book_new();
            // XLSX.utils.book_append_sheet(workbook, worksheet, 'Hoja1');

            // XLSX.writeFile(workbook, this.nombreExcel);
        },

        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },

        async PostProveedor(item) {
            console.log(item);
            let ObjProveedor = {
                rfc: item.rfc,
                nombre: item.nombre,
                nombreComercial: '',
                correo: '',
                telefono: '',
                direccion: '',
                contacto: '',
                estatus: 'Vigente',
                usuarioCreador: this.token.nombre,
                actaConstitutiva: {
                    base64: '',
                },
                opinionCumplimiento: {
                    base64: '',
                },
                comprobanteDomicilio: {
                    base64: ''
                },
            }

            this.loading = true;
            this.loadingMensaje = "Guardando Proveedor, espere...";
            try {
                let response = await axios.post("ProveedoresDocumentos/PostProveedor/" + this.token.empresaBase, ObjProveedor);
                let indice = this.itemsProveedores.findIndex(objeto => objeto.rfc === item.rfc);
                if (indice !== -1) {
                    this.itemsProveedores.splice(indice, 1);
                }
                this.loading = false;
                this.$toast.add({ severity: "success", summary: "Guardado", detail: "Proveedor guardado con exito.", life: 3000 });
            }
            catch (error) {
                console.log(error);
                this.loading = false;
                this.$toast.add({ severity: "error", summary: "Error", detail: "Error al guardar.", life: 3000 });
            }
        },

        OpenDialogContrato(item) {
            let buscaProveedor = this.listProveedores.find(x => x.rfc === item.rfc)
            let nuevo = { ...item }
            this.dialogContrato = true;

            let ObjContrato = {
                nombre: nuevo.contrato,
                fecha: new Date(),
                presupuesto: 0,
                proveedor: buscaProveedor,
            }

            this.$store.state.contratoStore = { ...ObjContrato }
        },

        CloseDialogContrato() {
            this.dialogContrato = false;
        },

    },
}
</script>