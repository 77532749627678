<template>
    <div>
        <!-- DETALLES GASTOS -->
        <Dialog :header="tituloDetalles" :visible.sync="dialogDetalles" :style="{ width: '90vw' }" :maximizable="false"
            :contentStyle="{ height: '700px' }" position="top" :modal="true">
            <DetallesGastos></DetallesGastos>
        </Dialog>

        <Card class="shadow-none">
            <template #content>
                <div class="p-fluid grid">
                    <!-- TITULO -->
                    <div class="col-8 text-left pa-0 ma-0 pl-3">
                        <h2>Gastos por Periodo</h2>
                    </div>
                    <!-- FECHAS -->
                    <div class="col-4 text-right">
                        <div class="text-left p-d-flex justify-center p-fluid grid formgrid">
                            <div class="col-12 md:col-12 text-left">
                                <div class="p-inputgroup">
                                    <span class="p-float-label">
                                        <Calendar id="monthpicker" v-model="fechaI" view="month" dateFormat="MM/yy"
                                            :yearNavigator="true" yearRange="2020:2030" />
                                        <label>Fecha Inicial</label>
                                    </span>
                                    <span class="p-float-label">
                                        <Calendar id="monthpicker" v-model="fechaF" view="month" dateFormat="MM/yy"
                                            :yearNavigator="true" yearRange="2020:2030" />
                                        <label>Fecha Final</label>
                                    </span>
                                    <Button icon="pi pi-search" class="p-button-success pl-4 pr-4" @click="getGastos()" />
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="field col-12 mt-2 mb-0">
                        <hr size=7 color="#E64747">
                    </div>
                </div>
                <DataTable removableSort :exportFilename="nombreExcel" ref="gastosMes" showGridlines :filters.sync="filtros"
                    class="mt-1 p-datatable-sm" :value="itemsGastos" :scrollable="true" scrollHeight="600px"
                    :loading="loadingGastos" :rows="15"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    :rowsPerPageOptions="[5, 10, 15, 25, 50]"
                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} Registros." :paginator="true">
                    <template #header>
                        <div class="flex justify-content-between">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filtros['global'].value" placeholder="Buscar" />
                            </span>
                            <Button icon="pi pi-external-link" label="Exportar Excel" @click="exportarGastos($event)" />
                        </div>
                    </template>

                    <Column sortable field="mes" header="Mes"
                        :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }">
                    </Column>

                    <Column sortable field="subTotal" header="SubTotal"
                        :bodyStyle="{ 'justify-content': 'right', overflow: 'visible' }">
                        <template #body="{ data }">
                            <span>{{ formatCurrency(data.subTotal) }}</span>
                        </template>
                    </Column>
                    <Column sortable field="descuento" header="Descuento"
                        :bodyStyle="{ 'justify-content': 'right', overflow: 'visible' }">
                        <template #body="{ data }">
                            <span>{{ formatCurrency(data.descuento) }}</span>
                        </template>
                    </Column>
                    <Column sortable field="total" header="Total"
                        :bodyStyle="{ 'justify-content': 'right', overflow: 'visible' }">
                        <template #body="{ data }">
                            <span>{{ formatCurrency(data.total) }}</span>
                        </template>
                    </Column>
                    <Column sortable header="Acciones" :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }">
                        <template #body="slotProps">
                            <Button @click="verDetalles(slotProps.data)" icon="pi pi-bars" class="p-button-rounded"
                                v-tooltip.bottom="'Total Formas de Pago'" />
                        </template>
                    </Column>
                    <ColumnGroup type="footer">
                        <Row>
                            <Column footer="Total:" :colspan="1" :footerStyle="{ 'text-align': 'right' }" />
                            <Column :footer="sumaSubtotal" :footerStyle="{ 'text-align': 'right' }" />
                            <Column :footer="sumaDescuento" :footerStyle="{ 'text-align': 'right' }" />
                            <Column :footer="sumaTotal" :footerStyle="{ 'text-align': 'right' }" />
                            <Column />
                        </Row>
                    </ColumnGroup>
                </DataTable>
            </template>
        </Card>
    </div>
</template>
<script>
import axios from 'axios'
import moment from 'moment'
import DetallesGastos from './DetalleGastos.vue'
import { FilterMatchMode, FilterOperator } from "primevue/api/";

export default {
    components: {
        DetallesGastos
    },
    data() {
        return {
            fechaI: new Date(),
            fechaF: new Date(),
            loadingGastos: false,
            itemsGastos: [],
            filtros: {
                global: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            },
            dialogDetalles: false,
            nombreExcel: '',
            tituloDetalles: ''

        };
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
        sumaSubtotal() {
            let total = 0;
            for (let item of this.itemsGastos) {
                total += item.subTotal;
            }

            return this.formatCurrency(total);
        },
        sumaDescuento() {
            let total = 0;
            for (let item of this.itemsGastos) {
                total += item.descuento;
            }

            return this.formatCurrency(total);
        },
        sumaTotal() {
            let total = 0;
            for (let item of this.itemsGastos) {
                total += item.total;
            }

            return this.formatCurrency(total);
        },

    },
    created() {
    },
    methods: {
        async getGastos() {
            this.itemsGastos = []
            this.loadingGastos = true;
            try {
                let fI = moment(this.fechaI).format('YYYY-MM-01')
                let ff = moment(this.fechaF).format('YYYY-MM-01')
                let response = await axios.get('ReporteComprobantesGastos/GetGastosPeriodo/' + this.token.empresaBase + '/' + fI + '/' + ff);
                console.log(response.data)
                this.itemsGastos = response.data
                this.loadingGastos = false;
            } catch (error) {
                console.log(error);
                this.loadingGastos = false;
            }
        },
        verDetalles(item) {
            this.tituloDetalles = 'Comprobantes - ' + item.mes
            console.log(item)
            this.dialogDetalles = true
            this.$store.state.listaDetallesGastosStore = []
            this.$store.state.listaDetallesGastosStore = item.detalle
        },
        exportarGastos() {
            let fI = moment(this.fechaI).format('MM-YYYY')
            let fF = moment(this.fechaI).format('MM-YYYY')
            this.nombreExcel = 'GastosPeriodo-' + fI + '_' + fF + '.xlsx'
            // this.$refs.gastosMes.exportCSV();

            const rows = this.$refs.gastosMes.processedData; // Obtén los datos de la DataTable
            console.log(rows)
            const worksheet = XLSX.utils.json_to_sheet(rows);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Hoja1');

            XLSX.writeFile(workbook, this.nombreExcel);
        },
        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },
    },
}
</script>