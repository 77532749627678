<template>
    <div>
        <!-- DIALOGO PARA ERRORES -->
        <div class="p-d-flex">
            <Toast />
        </div>

        <!-- DIALOGO CONFIRMAR ELIMINACION -->
        <Dialog :visible.sync="deleteProductDialog" :styles="{ width: '450px' }" header="Confirmar" :modal="true">
            <div class="confirmation-content">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                <span>Estas seguro de eliminar <b>{{ objetoEditado.rfc + " | " +
                    objetoEditado.nombre }}</b>?</span>
            </div>
            <template #footer>
                <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteProductDialog = false" />
                <Button label="Si" icon="pi pi-check" class="p-button-text" @click="eliminarProveedor()" />
            </template>
        </Dialog>

        <!-- DIALOGO DE LOADING -->
        <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
            <ProgressBar mode="indeterminate" style="height: 0.5em" />
            <span>{{ loadingMensaje }}</span>
        </Dialog>

        <!-- NUEVO PROVEEDOR -->
        <Dialog :visible.sync="dialogoNuevoProveedor" :closeOnEscape="true" :closable="false"
            :containerStyle="{ width: '1100px' }" position="top" :modal="true">
            <Proveedor @closeProveedor="closeDialogProveedor()"></Proveedor>
        </Dialog>

        <!-- VALIDACION LISTA -->
        <Dialog :visible.sync="dialogoValidacionLista" :closeOnEscape="true" :closable="true"
            :containerStyle="{ width: '1100px' }" position="top" :modal="true">
            <ValidacionesLista></ValidacionesLista>
        </Dialog>

        <!-- DIALOG CARPETA DE ARCHIVOS -->
        <Dialog :visible.sync="dialogoCarpeta" :header="tituloDoc" :closeOnEscape="true" :closable="true"
            :containerStyle="{ width: '600PX' }" position="center" :modal="true">
            <div class="field col-12 pb-0 mb-0 pt-0 mt-0">
                <hr size=7 color="#E64747">
            </div>
            <DataTable class="ocult" :value="listaArchivos" responsiveLayout="scroll">
                <Column header="">
                    <template #body="slotProps">
                        <Button @click="verDocumento(slotProps.data)" icon="mdi mdi-file-eye-outline"
                            class="p-button-rounded p-button-danger" v-tooltip.bottom="'Ver Documentos'" />
                    </template>

                </Column>
                <Column field="tipoArchivo" header=""></Column>
                <Column field="nombreArchivo" header=""></Column>
            </DataTable>
        </Dialog>


        <Card>
            <template #title>
                <div class="text-left p-d-flex justify-center p-fluid grid formgrid">
                    <!-- TITULO -->
                    <div class="col-6 text-left pa-0 ma-0 pl-3">
                        <span>Proveedores</span>
                    </div>
                    <div class="text-right justify-right field col-6 md:col-6">
                        <Button icon="pi pi-plus" @click="nuevoProveedor()" class="mr-1 p-button-rounded p-button-lg" />
                        <Button icon="pi pi-undo" @click="getProveedores()" class=" mr-1 p-button-rounded p-button-lg" />
                        <Button icon="pi pi-check" @click="ValidacionLista()" class="p-button-rounded p-button-lg" />
                    </div>
                </div>
            </template>

            <template #content>
                <DataTable filterDisplay="menu" removableSort :filters.sync="filtrosProveedor" class="mt-1 p-datatable-sm"
                    :value="itemsProveedores" :scrollable="true" scrollHeight="800px" scrollDirection="both"
                    :loading="loadingProveedor"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    :rowsPerPageOptions="[5, 10, 25]"
                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} Proveedores." :paginator="true"
                    :rows="10">
                    <template #header>
                        <div class="flex justify-content-between">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filtrosProveedor['global'].value" placeholder="Buscar" />
                            </span>
                            <Button type="button" icon="pi pi-filter-slash" style="width: 100px;" label="Limpiar"
                                class="p-button-outlined mr-2" @click="limpiarFiltros()" />
                        </div>
                    </template>
                    <Column header="Acciones" :bodyStyle="{ 'justify-content': 'left', overflow: 'visible' }"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '50px', 'min-width': '5rem', }">
                        <template #body="slotProps">
                            <Button @click="editarProveedor(slotProps.data)" icon="pi pi-pencil"
                                class="p-button-rounded p-button-success mr-2" v-tooltip.bottom="'Editar Proveedor'" />
                            <Button @click="confirmDeleteProduct(slotProps.data)" icon="pi pi-trash"
                                class="p-button-rounded p-button-danger mr-2" v-tooltip.bottom="'Eliminar Proveedor'" />
                            <Button @click="abrirCarpetaArchivos(slotProps.data)" icon="mdi mdi-folder"
                                class="p-button-rounded p-button-info" v-tooltip.bottom="'Ver Documentos'" />
                        </template>
                    </Column>
                    <Column sortable field="rfc" header="RFC"
                        :bodyStyle="{ 'justify-content': 'left', overflow: 'visible' }"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'text-align': 'center' }">
                        <template #filter="{ filterModel }">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="Buscar" />
                        </template>
                    </Column>
                    <Column sortable field="nombre" header="Nombre"
                        :bodyStyle="{ 'justify-content': 'left', overflow: 'visible' }"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'text-align': 'center' }">
                        <template #filter="{ filterModel }">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="Buscar" />
                        </template>
                    </Column>
                    <Column sortable field="correo" header="Correo"
                        :bodyStyle="{ 'justify-content': 'left', overflow: 'visible' }"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '200px', 'font-size': '90%' }">
                        <template #filter="{ filterModel }">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="Buscar" />
                        </template>
                    </Column>
                    <Column sortable field="telefono" header="Telefono"
                        :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '50px', 'font-size': '90%' }">
                        <template #filter="{ filterModel }">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="Buscar" />
                        </template>
                    </Column>
                </DataTable>
            </template>

        </Card>
    </div>
</template>
<script>
import { FilterMatchMode, FilterOperator } from "primevue/api/";
import axios from "axios";
import Proveedor from "./Proveedores.vue";
import print from 'print-js'
import ValidacionesLista from "../Validaciones/ValidacionesListas.vue";


export default {
    components: { Proveedor, ValidacionesLista },

    data() {
        return {
            loading: null,
            loadingMensaje: '',
            fechaI: new Date(),
            fechaF: new Date(),
            loadingProveedor: null,
            filtrosProveedor: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                'rfc': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'nombre': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'correo': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'telefono': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },

            },
            dialogoNuevoProveedor: false,
            deleteProductDialog: false,
            editedIndex: -1,
            objetoEditado: {},

            dialogoCarpeta: false,
            listaArchivos: [],
            dialogoValidacionLista: false,
            tituloDoc: ''
        }
    },
    computed: {
        empresa() {
            return this.$store.state.empresaStore;
        },
        token() {
            return this.$store.state.usuario;
        },
        itemsProveedores() {
            return this.$store.state.listaProveedorStore;
        },
        proveedor() {
            return this.$store.state.proveedorStore;
        }
    },
    created() {
        this.getProveedores();
    },
    methods: {
        async getProveedores() {
            this.loadingProveedor = true
            try {
                let response = await axios.get('ProveedoresDocumentos/GetProveedores/' + this.token.empresaBase);
                console.log(response.data)
                this.$store.state.listaProveedorStore = response.data
                this.loadingProveedor = false
            } catch (error) {
                console.log(error);
                this.loadingProveedor = false
            }
        },

        nuevoProveedor() {
            let nuevoProveedor = {
                idProveedor: 0,
                rfc: '',
                nombre: '',
                nombreComercial: '',
                correo: '',
                telefono: '',
                contacto: '',
                direccion: '',
                proveedor: '',
                actaConstitutiva: {
                    base64: '',
                    nombreArchivo: '',
                    tipoArchivo: ''
                },
                comprobanteDomicilio: {
                    base64: '',
                    nombreArchivo: '',
                    tipoArchivo: ''
                },
                opinionCumplimiento: {
                    base64: '',
                    nombreArchivo: '',
                    tipoArchivo: ''
                },
            }
            if (this.$store.state.proveedorStore.idProveedor != 0) {
                this.$store.state.proveedorStore = { ...nuevoProveedor }
            }
            this.dialogoNuevoProveedor = true;
        },
        confirmDeleteProduct(data) {
            this.editedIndex = this.itemsProveedores.indexOf(data)
            this.objetoEditado = data;
            this.deleteProductDialog = true;
        },
        async eliminarProveedor() {
            this.loading = true;
            this.loadingMensaje = 'Eliminando Proveedor, espere...'
            this.objetoEditado.usuarioModificador = this.token.nombre
            try {
                let response = await axios.put('Proveedores/PutProveedorEstatus/' + this.token.empresaBase, this.objetoEditado)
                console.log(response.data)
                this.itemsProveedores.splice(this.editedIndex, 1)

                this.loading = false;
                this.deleteProductDialog = false;

                this.$toast.add({ severity: 'success', detail: 'Proveedor eliminado con éxito.', life: 3000 });
            } catch (error) {
                console.log(error)
                this.$toast.add({ severity: 'error', detail: 'Error al eliminar, intente nuevamente.', life: 3000 });
                this.loading = false;
            }
        },
        editarProveedor(data) {
            this.$store.state.proveedorStore = { ...data };
            this.dialogoNuevoProveedor = true
        },
        closeDialogProveedor() {
            this.dialogoNuevoProveedor = false;
        },
        async abrirCarpetaArchivos(data) {
            this.tituloDoc = 'Documentos - ' + data.rfc
            this.listaArchivos = []

            try {
                let response = await axios.get('ProveedoresDocumentos/GetDocuementos/' + this.token.empresaBase + '/' + data.rfc);
                console.log(response.data)

                if (response.data.documentoActa.nombreArchivo != null) {
                    this.listaArchivos.push(response.data.documentoActa)
                }

                if (response.data.documentoComprobante.nombreArchivo != null) {
                    this.listaArchivos.push(response.data.documentoComprobante)
                }

                if (response.data.documentoOpinion.nombreArchivo != null) {

                    this.listaArchivos.push(response.data.documentoOpinion)
                }

                if (this.listaArchivos.length != 0) {
                    this.dialogoCarpeta = true
                } else {
                    this.$toast.add({ severity: 'info', detail: 'El proveedor no contiene archivos.', life: 3000 });
                }

            } catch (error) {
                console.log(error);
            }

        },

        async verDocumento(item) {
            let documento = item.base64
            // console.log(documento)
            print({
                printable: documento,
                type: 'pdf',
                base64: true,
            })
        },
        ValidacionLista() {
            this.dialogoValidacionLista = true
        },
        limpiarFiltros() {
            this.filtrosProveedor = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                'rfc': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'nombre': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'correo': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'telefono': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            }
        }
    },
}
</script>
<style>
.col {
    background-color: white;
}

.grid {
    background-color: white;
}

.ocult thead {
    display: none;
}

.ocult table {
    border: none
}
</style>