<template>
    <div class="card">

        <Dialog :visible.sync="dialogPdf" :style="{ width: '850px' }" :closable="false" :modal="true" position="top">
            <vista-pdf @cierraDialogPdf="closeDialogPdf"></vista-pdf>
        </Dialog>

        <!-- DIALOGO DE SPINNER -->
        <Dialog class="dialogoTransparente shadow-none" :showHeader="false" style="background: transparent;"
            :visible.sync="loadingSpinner" :closable="false" position="center" :containerStyle="{ width: '300px' }">
            <ProgressSpinner />
        </Dialog>

        <div class="flex justify-content-between flex-wrap pb-2">
            <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-button-outlined" @click="CloseVentana()" />
            <h2>Asigna Comprobantes</h2>
            <Button @click="PostComprobantesContrato()" icon="pi pi-save"
                class="p-button-rounded p-button-success p-button-outlined" />
        </div>
        <div>
            <h3>{{ cabecera.empresa }}</h3>
            <h4>{{ cabecera.contrato }}</h4>
            <h4>{{ formatCurrency(sumatoriaTotal) }}</h4>
        </div>
        <DataTable :value="itemsComprobantes" :scrollable="true" scrollHeight="550px" scrollDirection="both"
            :loading="loading" :filters.sync="filtros" filterDisplay="menu"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="[5, 10, 15, 25, 50]"
            currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} Registros." :paginator="true" :rows="15"
            :selection.sync="itemsSelected" selectionMode="multiple" dataKey="folioFiscal" :metaKeySelection="false">
            <Column header="Acciones" :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }" frozen
                :styles="{ 'flex-grow': '1', 'flex-basis': '50px', 'min-width': '5rem', }">
                <template #body="slotProps">
                    <Button @click="verPDF(slotProps.data)" icon="pi pi-file-pdf" class="p-button-rounded p-button-danger"
                        v-tooltip.bottom="'Ver Comprobante'" />
                </template>
            </Column>
            <Column sortable field="serie" header="Serie" :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }"
                :styles="{ 'flex-grow': '1', 'flex-basis': '50px', 'font-size': '90%' }">
                <template #filter="{ filterModel }">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Buscar" />
                </template>
            </Column>
            <Column sortable field="folio" header="Folio" :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }"
                :styles="{ 'flex-grow': '1', 'flex-basis': '50px', 'font-size': '90%' }">
                <template #filter="{ filterModel }">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Buscar" />
                </template>
            </Column>
            <Column sortable field="fecha" header="Fecha" :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }"
                :styles="{ 'flex-grow': '1', 'flex-basis': '50px', 'font-size': '90%' }">
                <template #filter="{ filterModel }">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Buscar" />
                </template>
            </Column>
            <Column sortable field="subTotal" header="SubTotal"
                :bodyStyle="{ 'justify-content': 'right', overflow: 'visible' }"
                :styles="{ 'flex-grow': '1', 'flex-basis': '50px', 'font-size': '90%' }">
                <template #body="{ data }">
                    <span>{{ formatCurrency(data.subTotal) }}</span>
                </template>
                <template #filter="{ filterModel }">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Buscar" />
                </template>
            </Column>
            <Column sortable field="descuento" header="Descuento"
                :bodyStyle="{ 'justify-content': 'right', overflow: 'visible' }"
                :styles="{ 'flex-grow': '1', 'flex-basis': '50px', 'font-size': '90%' }">
                <template #body="{ data }">
                    <span>{{ formatCurrency(data.descuento) }}</span>
                </template>
                <template #filter="{ filterModel }">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Buscar" />
                </template>
            </Column>
            <Column sortable field="total" header="Total" :bodyStyle="{ 'justify-content': 'right', overflow: 'visible' }"
                :styles="{ 'flex-grow': '1', 'flex-basis': '50px', 'font-size': '90%' }">
                <template #body="{ data }">
                    <span>{{ formatCurrency(data.total) }}</span>
                </template>
                <template #filter="{ filterModel }">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Buscar" />
                </template>
            </Column>
            <Column sortable field="tipoComprobante" header="Tipo de Comprobante"
                :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }"
                :styles="{ 'flex-grow': '1', 'flex-basis': '50px', 'font-size': '90%' }">
                <template #filter="{ filterModel }">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Buscar" />
                </template>
            </Column>
            <Column sortable field="folioFiscal" header="Folio Fiscal"
                :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }"
                :styles="{ 'flex-grow': '1', 'flex-basis': '10px', 'font-size': '90%' }">
                <template #filter="{ filterModel }">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Buscar" />
                </template>
            </Column>
        </DataTable>
    </div>
</template>
<script>
import axios from 'axios'
import moment from 'moment'
import VistaPdf from '../Pdf/ComprobantePdf.vue'
import { FilterMatchMode, FilterOperator } from "primevue/api/";

export default {
    components: {
        VistaPdf
    },
    data() {
        return {
            loading: false,
            itemsSelected: [],
            dialogPdf: false,
            blockedDocument: false,
            loadingSpinner: false,
            filtros: {
                global: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                'serie': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'folio': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'fecha': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'subTotal': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                'descuento': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                'total': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                'tipoComprobante': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'folioFiscal': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            },

        };
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
        itemsComprobantes() {
            return this.$store.state.ComprobantesContratosStore;
        },
        cabecera() {
            return this.$store.state.CabeceraStore;
        },
        sumatoriaTotal() {
            let compro = this.itemsSelected
            let total = compro.reduce((acumulado, venta) => acumulado + venta.total, 0);
            return total;
        },

    },
    created() {
        this.AsignaPre();
    },
    methods: {
        AsignaPre() {
            this.itemsSelected = [... this.$store.state.ComprobantesContratosSelectedStore]
        },

        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },

        async PostComprobantesContrato() {
            let comprobantes = [];
            let idContrato = this.cabecera.idContrato
            try {
                for (var x of this.itemsSelected) {
                    comprobantes.push(x.folioFiscal);
                }
                let response = await axios.post('Contratos/PostContratosComprobantesCompleto/' + this.token.empresaBase + '/' + idContrato, comprobantes);
                console.log(response.data);
                this.CloseVentana();
            } catch (error) {
                console.log(error)
            }
        },

        CloseVentana() {
            this.$emit("closeComprobantes");
        },

        async verPDF(item) {
            this.loadingSpinner = true

            console.log(item)
            try {
                let response = await axios.get('Comprobantes/GetComprobanteFolioFiscalG/' + this.token.empresaBase + '/' + item.folioFiscal);
                let respuesta = response.data;
                this.$store.state.comprobanteStore = { ...respuesta[0] }
                this.loadingSpinner = false
                this.dialogPdf = true;
            } catch (error) {
                console.log(error)
                this.loadingSpinner = false
            }
        },
        closeDialogPdf() {
            this.dialogPdf = false;
        },
    },
}
</script>