<template>
    <div>
        <div class="card">
            <TabView>
                <TabPanel header="Reporte Objeto del Gasto">
                    <ObjetoGastosReporte></ObjetoGastosReporte>
                </TabPanel>
            </TabView>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import ObjetoGastosReporte from './ObjetoGastosReporte.vue'
export default {
    components: { ObjetoGastosReporte },
    data() {
        return {
        };
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
    },
    created() {
    },
    methods: {


    },
}
</script>