<template>
    <div>
        <div class="card">
            <TabView>
                <TabPanel header="Gastos por Mes">
                    <GastosMes></GastosMes>
                </TabPanel>
                <TabPanel header="Gastos por Periodo">
                    <GastosPeriodo></GastosPeriodo>
                </TabPanel>
                <TabPanel header="Comparativa">
                    <GastosComparativa></GastosComparativa>
                </TabPanel>
                <TabPanel header="Gastos por RFC">
                    <GastosRFC></GastosRFC>
                </TabPanel>
            </TabView>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import GastosMes from './GastosMes.vue'
import GastosComparativa from './GastosComparativa.vue'
import GastosPeriodo from './GastosPeriodo.vue'
import GastosRFC from './GastosRFC.vue'
export default {
    components: { GastosMes, GastosComparativa, GastosPeriodo, GastosRFC },
    data() {
        return {
        };
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
    },
    created() {
    },
    methods: {


    },
}
</script>