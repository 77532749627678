<template>
    <div>
        <div class="card">
            <div>
                <h2>Validar en lista de 69B y no Localizados</h2>
            </div>
            <div class="p-fluid grid">
                <!-- RFC -->
                <div class="col-12">
                    <div class="p-inputgroup">
                        <span class="p-float-label">
                            <InputText type="text" v-model="rfc" />
                            <label>RFC</label>
                        </span>
                        <Button icon="pi pi-search" class="p-button-success" @click="GetListas()" />
                    </div>
                </div>
            </div>
            <!-- LISTA NEGRA -->
            <div class="p-fluid grid" v-if="listaNegra.nombre != '0'">
                <br>
                <h3>Lista Negra</h3>
                <div class="col-12">
                    <span class="p-float-label">
                        <InputText type="text" v-model="listaNegra.nombre" />
                        <label>Nombre</label>
                    </span>
                </div>
                <div class="col-12">
                    <span class="p-float-label">
                        <InputText type="text" v-model="listaNegra.situacion" />
                        <label>Situación</label>
                    </span>
                </div>
            </div>
            <!-- LISTA NO LOCALIZADOS -->
            <div class="p-fluid grid" v-if="noLocalizados.nombre != '0'">
                <br>
                <h3>No Localizados</h3>
                <div class="col-12">
                    <span class="p-float-label">
                        <InputText type="text" v-model="noLocalizados.nombre" />
                        <label>Nombre</label>
                    </span>
                </div>
                <div class="col-12">
                    <span class="p-float-label">
                        <InputText type="text" v-model="noLocalizados.supuesto" />
                        <label>Supuesto</label>
                    </span>
                </div>
                <div class="col-12">
                    <span class="p-float-label">
                        <InputText type="text" v-model="noLocalizados.fechaPrimeraPublicacion" />
                        <label>Fecha Primera Publicación</label>
                    </span>
                </div>
                <div class="col-12">
                    <span class="p-float-label">
                        <InputText type="text" v-model="noLocalizados.entidadFederativa" />
                        <label>Entidad Federativa</label>
                    </span>
                </div>
            </div>
            <div v-if="listaNegra.nombre === '_'">
                <h1 style="color: green;">No se encuentra en lista de 69B</h1>
            </div>
            <div v-if="noLocalizados.nombre === '_'">
                <h1 style="color: green;">No se encuentra en lista de no localizados</h1>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import { FilterMatchMode, FilterOperator } from 'primevue/api/';

export default {
    components: {

    },
    data() {
        return {
            rfc: '',
            listaNegra: {
                nombre: '',
                situacion: '',
            },
            noLocalizados: {
                nombre: '',
                supuesto: '',
                fechaPrimeraPublicacion: '',
                entidadFederativa: '',
            },
        };
    },
    computed: {

    },
    created() {

    },
    methods: {
        async GetListas() {
            try {
                let response = await axios.get('ValidaListas/GetListas/' + this.rfc);
                console.log(response.data)
                let resultado = response.data;
                this.listaNegra = { ...resultado.listaNegra }
                this.noLocalizados = { ...resultado.noLocalizados }
            } catch (error) {
                console.log(error);
            }
        },
    },
}
</script>