import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Configuracion from '../views/Configuracion.vue'

import Login from '../views/Login.vue'
import store from '../store/index'
import ListaCancelaciones from '../components/Cancelaciones/ListaCancelaciones.vue'
import ListaCatalogo from '../components/Catalogo/ListaCatalogo.vue'
import ListaConceptos from '../components/Conceptos/ListaConceptos.vue'
import ContratosGeneral from '../components/Contratos/ContratosGeneral.vue'
import ListaContratos from '../components/Contratos/ListaContratos.vue'
import ListaProveedores from '../components/Proveedores/ListaProveedores.vue'
import General from '../components/General/General.vue'
import GastosComparativa from '../components/General/GastosComparativa.vue'
import GastosMes from '../components/General/GastosMes.vue'
import GastosPeriodo from '../components/General/GastosPeriodo.vue'
import GastosRFC from '../components/General/GastosRFC.vue'
import Conceptos from '../components/Conceptos/Conceptos.vue'
import CuentasPagar from '../components/CuentasPagar/CuentasPagar.vue'
import ObjetoGastos from '../components/ObjetoGastos/ObjetoGastos.vue'
import Isr from '../components/Isr/Isr.vue'


Vue.use(VueRouter)

const routes = [
  { path: '/Login', name: 'Login', component: Login, meta: { libre: true } },
  { path: '/', name: 'Home', component: Home, meta: { Administrador: true } },
  { path: '/Configuracion', name: 'Configuracion', component: Configuracion, meta: { Administrador: true } },
  { path: '/ListaCancelaciones', name: 'ListaCancelaciones', component: ListaCancelaciones, meta: { Administrador: true } },
  { path: '/ListaCatalogo', name: 'ListaCatalogo', component: ListaCatalogo, meta: { Administrador: true } },
  { path: '/ListaConceptos', name: 'ListaConceptos', component: ListaConceptos, meta: { Administrador: true } },
  { path: '/ContratosGeneral', name: 'ContratosGeneral', component: ContratosGeneral, meta: { Administrador: true } },
  { path: '/ListaContratos', name: 'ListaContratos', component: ListaContratos, meta: { Administrador: true } },
  { path: '/ListaProveedores', name: 'ListaProveedores', component: ListaProveedores, meta: { Administrador: true } },
  { path: '/General', name: 'General', component: General, meta: { Administrador: true } },
  { path: '/GastosComparativa', name: 'GastosComparativa', component: GastosComparativa, meta: { Administrador: true } },
  { path: '/GastosMes', name: 'GastosMes', component: GastosMes, meta: { Administrador: true } },
  { path: '/GastosPeriodo', name: 'GastosPeriodo', component: GastosPeriodo, meta: { Administrador: true } },
  { path: '/GastosRFC', name: 'GastosRFC', component: GastosRFC, meta: { Administrador: true } },
  { path: '/Conceptos', name: 'Conceptos', component: Conceptos, meta: { Administrador: true } },
  { path: '/CuentasPagar', name: 'CuentasPagar', component: CuentasPagar, meta: { Administrador: true } },
  { path: '/ObjetoGastos', name: 'ObjetoGastos', component: ObjetoGastos, meta: { Administrador: true } },
  { path: '/Isr', name: 'Isr', component: Isr, meta: { Administrador: true } },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.libre)) {
    next()
  } else if (store.state.usuario && store.state.usuario.rol == 'Administrador') {
    if (to.matched.some(record => record.meta.Administrador)) {
      next()
    }
  } else {
    next({
      name: 'Login'
    })
  }
})
export default router
