<template>
    <div>
        <div class="card">
            <TabView>
                <TabPanel header="Cuentas por Pagar por Periodo">
                    <CuentasPagarPeriodo></CuentasPagarPeriodo>
                </TabPanel>
                <TabPanel header="Cuentas por Pagar por RFC">
                    <CuentasPagarRFC></CuentasPagarRFC>
                </TabPanel>
            </TabView>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import CuentasPagarPeriodo from './CuentasPagarPeriodo.vue'
import CuentasPagarRFC from './CuentasPagarRFC.vue'
export default {
    components: { CuentasPagarPeriodo, CuentasPagarRFC },
    data() {
        return {
        };
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
    },
    created() {
    },
    methods: {


    },
}
</script>