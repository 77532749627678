<template>
    <div class="card">
        <!-- DIALOGO DE LOADING -->
        <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
            <ProgressBar mode="indeterminate" style="height: 0.5em" />
            <span>{{ loadingMensaje }}</span>
        </Dialog>

        <div class="flex justify-content-between flex-wrap pb-2">
            <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-button-outlined" @click="CloseVentana()" />
            <h2>Contrato</h2>
            <Button @click="PostOrPut()" icon="pi pi-save" class="p-button-rounded p-button-success p-button-outlined" />
        </div>
        <div class="p-fluid grid">
            <div class="field col-12">
                <hr size=7 color="#E64747">
            </div>
            <!-- CONTRATO -->
            <div class="field col-12">
                <span class="p-float-label">
                    <InputText type="text" v-model="contrato.nombre" />
                    <label>Contrato</label>
                </span>
            </div>
            <!-- FECHA -->
            <div class="field col-12 md:col-6">
                <span class="p-float-label">
                    <!-- <InputText id="rfc" type="text" v-model="contrato.fecha" maxlength="13" /> -->
                    <Calendar v-model="contrato.fecha" dateFormat="dd/MM/yy" />
                    <label>Fecha</label>
                </span>
            </div>
            <!-- PRESUPUESTO -->
            <div class="field col-12 md:col-6">
                <span class="p-float-label">
                    <InputNumber v-model="contrato.presupuesto" mode="currency" currency="USD" locale="en-US" />
                    <label>Presupuesto</label>
                </span>
            </div>
            <!-- PROVEEDOR -->
            <div class="field col-12">
                <span class="p-float-label">
                    <AutoComplete style="text-align: left" v-model="contrato.proveedor" :suggestions="filteredProveedores"
                        @complete="searchProveedor($event)" field="proveedor" />
                    <label>Proveedor</label>
                </span>
            </div>
            <!-- CUENTA OG -->
            <div class="field col-12">
                <span class="p-float-label">
                    <AutoComplete style="text-align: left" v-model="contrato.cuenta" :suggestions="filteredCuentaOg"
                        @complete="searchCuentaOg($event)" field="cuenta" />
                    <label>Cuenta OG</label>
                </span>
            </div>

        </div>
    </div>
</template>
<script>
import axios from 'axios'
import { FilterMatchMode, FilterOperator } from 'primevue/api/';

export default {
    data() {
        return {
            filteredProveedores: null,
            filteredCuentaOg: null,
            itemsCatalogo: [],
            loading: false,
            loadingMensaje: '',
        };
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },

        contrato() {
            return this.$store.state.contratoStore;
        },

        listProveedores() {
            return this.$store.state.listaProveedorStore
        },

    },
    created() {
        this.GetProveedores();
        this.GetCatalogo();
    },
    methods: {
        async GetProveedores() {
            try {
                let response = await axios.get('ProveedoresDocumentos/GetProveedoresSimplificado/' + this.token.empresaBase);
                this.$store.state.listaProveedorStore = response.data
            } catch (error) {
                console.log(error);
            }
        },

        async GetCatalogo() {
            this.loadingCatalogo = true;
            this.itemsCatalogo = []
            try {
                let response = await axios.get('Contratos/GetCuentaOg/' + this.token.empresaBase);
                let resultado = [...response.data]
                let pre = resultado.filter((x) => x.tipo === 'R');
                let fin = pre.filter((cadena) => /^[2-4]/.test(cadena.numero));
                console.log(fin)
                this.itemsCatalogo = [...fin]
            } catch (error) {
                console.log(error);
            }
        },

        searchProveedor(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filteredProveedores = [...this.listProveedores];
                }
                else {
                    this.filteredProveedores = this.listProveedores.filter((country) => {
                        return country.proveedor.toLowerCase().includes(event.query.toLowerCase());
                    });
                }
            }, 250);
        },

        searchCuentaOg(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filteredCuentaOg = [...this.itemsCatalogo];
                }
                else {
                    this.filteredCuentaOg = this.itemsCatalogo.filter((country) => {
                        return country.cuenta.toLowerCase().includes(event.query.toLowerCase());
                    });
                }
            }, 250);
        },

        PostOrPut() {
            // if (this.$store.state.proveedorStore.idProveedor == 0) {
            this.PostContrato();
            // }
            // else {
            //     this.PutContrato();
            // }
        },

        async PostContrato() {
            //VALIDAMOS
            console.log(this.contrato.presupuesto)
            var presupuestoDecimal = parseFloat(this.contrato.presupuesto);

            if (this.contrato.nombre === '') {
                this.$toast.add({ severity: "error", summary: "Error", detail: "Indique un nombre para el contrato", life: 3000 });
                return;
            }
            if (presupuestoDecimal <= 0) {
                this.$toast.add({ severity: "error", summary: "Error", detail: "Indique un presupuesto mayor a 0.", life: 3000 });
                return;
            }
            if (!this.contrato.proveedor.rfc) {
                this.$toast.add({ severity: "error", summary: "Error", detail: "Seleccione un proveedor", life: 3000 });
                return;
            }
            if (!this.contrato.cuenta) {
                this.$toast.add({ severity: "error", summary: "Error", detail: "Seleccione una cuenta", life: 3000 });
                return;
            }
            this.loading = true;
            this.loadingMensaje = "Guardando espere...";
            let ObjContrato = {
                nombre: this.contrato.nombre,
                fecha: this.contrato.fecha,
                presupuesto: this.contrato.presupuesto,
                rfc: this.contrato.proveedor.rfc,
                idCuenta: this.contrato.cuenta.id,
                usuarioCreador: this.token.nombre,
            };
            try {
                let response = await axios.post("Contratos/PostContartos/" + this.token.empresaBase, ObjContrato);
                console.log(response.data);
                this.loading = false;
                let ObjExito = {
                    id: response.data,
                    nombre: this.contrato.nombre,
                    fecha: this.contrato.fecha,
                    rfc: this.contrato.proveedor.rfc,
                    nombreProveedor: this.contrato.proveedor.nombre,
                    presupuesto: this.contrato.presupuesto,
                    cuenta: this.contrato.cuenta.cuenta
                }
                this.$store.state.itemsContratosStore.push(ObjExito);
                this.$toast.add({ severity: "success", summary: "Guardado", detail: "Proveedor guardado con exito.", life: 3000 });

                //EN EL CASO DE SER ALTA DESDE CONTRATOS NO REGISTRADOS, LO QUITAMOS DE LA LISTA
                let indice = this.$store.state.itemsContratosConciliaStore.findIndex(x => x.rfc === ObjExito.rfc && x.contrato === ObjExito.nombre)
                this.$store.state.itemsContratosConciliaStore.splice(indice, 1);
                this.CloseVentana();
            }
            catch (error) {
                console.log(error);
                this.loading = false;
                this.$toast.add({ severity: "error", summary: "Error", detail: "Error al guardar.", life: 3000 });
            }
        },

        async PutContrato() {
            this.loading = true;
            this.loadingMensaje = "Actualizando Proveedor, espere...";
            this.proveedor.usuarioModificador = this.token.nombre;
            try {
                let response = await axios.put("ProveedoresDocumentos/PutProveedor/" + this.token.empresaBase, this.proveedor);
                console.log(response.data);
                this.loading = false;
                //BUSCAMOS EL INDICE
                let indice = this.$store.state.listaProveedorStore.findIndex(x => x.idProveedor === this.proveedor.idProveedor);
                Object.assign(this.$store.state.listaProveedorStore[indice], this.proveedor);
                this.$toast.add({ severity: "success", summary: "Actualizado", detail: "Proveedor actualizado con exito.", life: 3000 });
                this.cierraVentana();
            }
            catch (error) {
                console.log(error);
                this.loading = false;
                this.$toast.add({ severity: "error", summary: "Error", detail: "Error al actualizar.", life: 3000 });
            }
        },

        CloseVentana() {
            this.$emit("closeContrato");
        },
    },
}
</script>
