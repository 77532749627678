<template>
    <div>
        <!-- DIALOGO DE LOADING -->
        <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
            <ProgressBar mode="indeterminate" style="height: 0.5em" />
            <span>{{ loadingMensaje }}</span>
        </Dialog>

        <div class="flex justify-content-between flex-wrap pb-2">
            <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-button-outlined"
                @click="cierraVentana()" />
            <h2>Objeto de Gasto</h2>
            <Button @click="postCatalogo()" icon="pi pi-save" class="p-button-rounded p-button-success p-button-outlined" />
        </div>
        <div class="p-fluid grid">
            <div class="field col-12">
                <hr size=7 color="#E64747">
            </div>
            <!-- RFC -->
            <div class="field col-12">
                <span class="p-float-label">
                    <span class="p-float-label">
                        <InputText type="text" v-model="catalogo.numero" />
                        <label for="rfc">Número</label>
                    </span>
                </span>
            </div>
            <div class="field col-12">
                <span class="p-float-label">
                    <span class="p-float-label">
                        <InputText type="text" v-model="catalogo.nombre" />
                        <label>Nombre</label>
                    </span>
                </span>
            </div>
            <div class="field col-12">
                <span class="p-float-label">
                    <span class="p-float-label">
                        <InputText type="text" v-model="catalogo.tipo" />
                        <label>Tipo</label>
                    </span>
                </span>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    components: {},
    data() {
        return {
            loading: false,
            loadingMensaje: "",
        };
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
        catalogo() {
            return this.$store.state.catalogoStore
        }
    },
    created() {
    },
    methods: {
        cierraVentana() {
            this.$emit("closeCatalogo");
            this.$store.state.catalogoStore.id = -1
        },
        async postCatalogo() {
            this.loading = true;
            this.loadingMensaje = "Guardando espere...";
            try {
                let response = await axios.post("Contratos/PostObjetoGasto/" + this.token.empresaBase, this.catalogo);
                console.log(response.data);
                this.catalogo.id = response.data;
                this.$store.state.listaCatalogoStore.push(this.catalogo);
                this.loading = false;
                this.$toast.add({ severity: "success", summary: "Guardado", detail: "guardado con exito.", life: 3000 });
                this.cierraVentana();
            }
            catch (error) {
                console.log(error);
                this.loading = false;
                this.$toast.add({ severity: "error", summary: "Error", detail: "Error al guardar.", life: 3000 });
            }
        },
    },
}
</script>